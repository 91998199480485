import { logo_docad } from 'app/logo_Muhat';
import { GlobalConstants } from 'app/global-constants';
import pdfMake from 'pdfmake/build/pdfmake';

import pdfFonts from '../font/vfs_fonts.js';



export default function generateCodeParcellaire(code,dossier) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.fonts = {
        calibri: {
            normal: 'regular.otf',
            bold: "bold.otf",
            italics: "italic.otf",
            bolditalics: 'bold-italic.otf'
        },
        arial: {
            normal: 'arial.ttf',
            bold: 'Arial-Bold.ttf',
            italics: 'Arial-Italic.ttf',
            bolditalics: 'Arial-Bold-Italic.ttf'
        },
    }
    const documentDefinition = {
        info: {
            title: "CODE PARCELLAIRE",
            author: 'D & Ntech',
            subject: 'FICHE',
        },
        pageSize: { height: 150, width: 200},
        watermark: { text: 'SCOF',  opacity: 0.2, bold: true, italics: false },
        footer: {
        columns: [
              { text: GlobalConstants.formatDate(dossier?.created_date), alignment: 'center',bold:true,italic:true }
            ]
          },
        content: [
            {
                text: code,fontSize:16,bold:true,
                margin: [0,-20,0,0],
            },
            {columns: [
                {
                    image: logo_docad,
                    width: 60,
                    height: 60,
                    margin: [0, 0, 0, 0]
                },
                {
                    qr: `${window.location.href}`,
                    margin: [0,0,0,0],
                    fit: '70',
                    alignment: 'right'
                    ,
                }
              ],
            },
        ],
        defaultStyle: {
            color: '#000',
            font: 'calibri'
        },

    }
    pdfMake.createPdf(documentDefinition).download('parcelle_'+ code + '.pdf')
}
